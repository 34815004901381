<template>
  <v-stepper-content :step="step" class="d-flex fill">
    <h2 class="mt-5 primary--text word-break">
      TERMO DE ADESÃO AO PROGRAMA ALGODÃO BRASILEIRO RESPONSÁVEL PARA UNIDADE DE
      BENEFICIAMENTO DE ALGODÃO - ANEXO I DO REGULAMENTO - SAFRA
      {{ detail.algodoeira.safra }}
    </h2>
    <p class="mt-5">
      NOMEAÇÃO DE REPRESENTANTE E DECLARAÇÃO ÉTICA DE COMPROMISSO COM A PRÁTICA
      DA SUSTENTABILIDADE
    </p>

    <h3 class="my-3 primary--text word-break">
      Anexo I do Regulamento
    </h3>

    <v-row>
      <v-col sm="8">
        <v-card color="gray-1" elevation="0">
          <v-card-text>
            <h3 class="primary-text--text word-break">
              Dados da Unidade de Beneficiamento do Algodão
            </h3>
            <v-row class="mt-2">
              <v-col sm="6">
                <labelled-info label="Nome Fantasia">
                  {{ detail.algodoeira.nome_fantasia || defaulEmptyData }}
                </labelled-info>
              </v-col>
              <v-col sm="6">
                <labelled-info label="Proprietário/Produtor/Grupo">
                  <p
                    v-if="
                      detail.algodoeira.proprietarios[0].cpf_cnpj.length > 11
                    "
                    class="ma-0 primary-text--text"
                  >
                    <strong> - {{ detail.algodoeira.razao_social }} </strong>
                    <strong
                      v-if="detail.algodoeira.proprietarios.length > 1"
                      style="padding-left: 7px;"
                    >
                      E Outros
                    </strong>
                  </p>
                  <p v-else class="ma-0 primary-text--text">
                    <strong>
                      - {{ detail.algodoeira.proprietarios[0].nome }}
                    </strong>
                    <strong
                      v-if="detail.algodoeira.proprietarios.length > 1"
                      style="padding-left: 7px;"
                    >
                      E Outros
                    </strong>
                  </p>
                </labelled-info>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col sm="6">
                <labelled-info label="CPF/CNPJ">
                  {{ detail.algodoeira.cpf_cnpj || defaultEmptyData }}
                </labelled-info>
              </v-col>
              <v-col sm="6">
                <labelled-info label="Categoria">
                  {{ detail.algodoeira.categoria || defaulEmptyData }}
                </labelled-info>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col sm="6">
                <labelled-info label="Município">
                  {{ detail.algodoeira.municipio || defaultEmptyData }}
                </labelled-info>
              </v-col>
              <v-col sm="6">
                <labelled-info label="UF">
                  {{ detail.algodoeira.uf || defaultEmptyData }}
                </labelled-info>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col sm="6">
                <labelled-info label="Código Dig. Extensão + GS1 (SAI)">
                  {{
                    detail.algodoeira.codigo_gs1_digito_extensao ||
                      defaultEmptyData
                  }}
                </labelled-info>
              </v-col>
              <v-col sm="6">
                <labelled-info label="Associação Estadual">
                  {{ detail.algodoeira.associada || defaultEmptyData }}
                </labelled-info>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card class="fill-height" color="gray-1" elevation="0">
          <v-card-text>
            <h3 class="primary-text--text word-break">
              Dados do Responsável pela Unidade de Beneficiamento
            </h3>

            <v-row class="mt-2">
              <v-col sm="12">
                <labelled-info label="Nome">
                  {{ detail.convidado.nome }}
                </labelled-info>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col sm="12">
                <labelled-info label="E-mail">
                  {{ detail.convidado.email }}
                </labelled-info>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col sm="12">
                <labelled-info label="Telefone (DDD/Nº)">
                  {{ detail.convidado.telefone }}
                </labelled-info>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <p class="mt-7 primary-text--text text-justify word-break">
      Por meio do presente termo, a unidade de beneficiamento de algodão e o
      proprietário/produtor/grupo acima identificados solicitam sua adesão ao
      <strong class="word-break"
        >Programa Algodão Brasileiro Responsável para Unidade de Beneficiamento
        de Algodão – ABR-UBA,</strong
      >
      autorizando a equipe técnica da Associação Estadual e os auditores da
      certificadora contratada, devidamente credenciados e identificados, a
      proceder à verificação de campo em sua unidade e no escritório responsável
      pela documentação dos recursos humanos e constatar através da
      <strong class="word-break"
        >(1) Lista de Verificação para Diagnóstico da Unidade de Beneficiamento
        (VDB)</strong
      >
      e
      <strong class="word-break"
        >(2) Lista de Verificação para Certificação da Unidade de Beneficiamento
        (VCB) para a safra {{ detail.algodoeira.safra }},</strong
      >
      o cumprimento dos princípios legais e constantes do Regulamento do
      Programa ABR-UBA, visando à obtenção do Certificado Algodão Brasileiro
      Responsável para Unidade de Beneficiamento de Algodão.
    </p>
    <p class="mt-7 primary-text--text text-justify word-break">
      A unidade de beneficiamento de algodão e o proprietário/produtor/grupo
      autorizam o uso pela Associação Estadual de imagens digitalizadas das
      conformidades e adequações executadas na unidade. Através de seu
      representante legal assinado e qualificado, declara espontaneamente e, sob
      compromisso de honra, que tem conhecimento dos princípios éticos e legais
      que integram o regulamento de concessão do Certificado. Declara, também,
      que seu beneficiamento não utiliza nem utilizará mão de obra infantil nem
      qualquer forma de trabalho forçado ou análogo ao escravo, degradante ou
      indigno nas relações trabalhistas.
    </p>
    <p class="mt-7 primary-text--text text-justify word-break">
      A unidade de beneficiamento de algodão e o proprietário/produtor/grupo
      reconhecem ainda que o processo de certificação do Programa ABR-UBA será
      celebrado por ela ou pela Associação Estadual diretamente com a empresa
      certificadora credenciada pela Abrapa.
    </p>
    <p class="mt-7 primary-text--text text-justify word-break">
      A unidade de beneficiamento de algodão e ou proprietário/produtor/grupo
      declaram que são inscritos e ativos no Sistema Abrapa de Identificação
      (SAI) e autorizam o uso do número do certificado ABR-UBA tão somente em
      relação aos fardinhos beneficiados com algodão brasileiro de comprovada
      origem.
    </p>

    <div class="d-flex justify-end">
      <v-btn
        color="refuse"
        class="px-4 white--text"
        @click="
          $confirmDialog.open(
            'Você confirma que não deseja participar do Programa ABR-UBA?',
            handleRefuseTerm
          )
        "
      >
        Desejo recusar participação ao Programa ABR-UBA
      </v-btn>

      <v-btn
        color="accept"
        class="mx-4 white--text"
        @click="
          $confirmDialog.open(
            'Você confirma a adesão ao Programa ABR-UBA?',
            handleAcceptTerm
          )
        "
        >Estou ciente e desejo aderir ao Programa ABR-UBA
      </v-btn>
    </div>
  </v-stepper-content>
</template>

<script>
import LabelledInfo from "@/modules/core/views/components/LabelledInfo";

export default {
  name: "StepTerms",

  props: {
    step: {
      type: Number,
      required: true
    },
    detail: {
      type: Object,
      required: true
    },
    fnSendNotion: {
      type: Function,
      required: true
    }
  },

  components: {
    LabelledInfo
  },

  data: () => ({
    defaultEmptyData: "Não registrado",
    harvest: "2020/2021"
  }),

  methods: {
    nextStep() {
      this.$emit("onNext", this.step + 1);
    },

    previusStep() {
      this.$emit("onPrevious", this.step - 1);
    },

    handleRefuseTerm() {
      this.fnSendNotion(false, () => this.nextStep());
    },

    handleAcceptTerm() {
      this.fnSendNotion(true, () => this.nextStep());
    }
  }
};
</script>

<style lang="scss" scoped>
.word-break {
  word-break: break-word;
}
</style>
