<template>
  <div>
    <h1 class="mb-4 page-title">Responder Convite</h1>

    <v-stepper v-model="currentStep">
      <v-stepper-header>
        <template v-for="(step, n) in steps">
          <v-stepper-step
            :key="step.step"
            :complete="currentStep > step.step"
            :step="step.step"
            color="#185080"
          >
            {{ step.label }}
          </v-stepper-step>
          <v-divider
            v-if="n < maxSteps - 1"
            :key="`step-divider-${step.step}`"
          ></v-divider>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <step-regulation
          v-if="currentStep === stepsEnum.REGULATION"
          :step="stepsEnum.REGULATION"
          @onNext="nextStep"
          @onPrevious="previousStep"
          :detail="detail"
        />

        <step-terms
          v-if="currentStep === stepsEnum.TERMS"
          :step="stepsEnum.TERMS"
          :detail="detail"
          :fnSendNotion="sendNotion"
          @onNext="nextStep"
          @onPrevious="previousStep"
        />

        <step-confirmation
          :accepted="accepted"
          :detail="detail"
          :step="stepsEnum.CONFIRMATION"
          v-if="currentStep === stepsEnum.CONFIRMATION"
        />
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import StepRegulation from "@/modules/invite-uba/views/components/answer-invite/StepRegulation";
import StepTerms from "@/modules/invite-uba/views/components/answer-invite/StepTerms";
import StepConfirmation from "@/modules/invite-uba/views/components/answer-invite/StepConfirmation.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "AnswerInviteUBA",

  components: {
    StepRegulation,
    StepTerms,
    StepConfirmation
  },

  data: () => ({
    currentStep: 1,
    maxSteps: 3,
    stepsEnum: {
      REGULATION: 1,
      TERMS: 2,
      CONFIRMATION: 3
    },
    steps: [
      {
        step: 1,
        label: "Regulamento ABR-UBA"
      },
      {
        step: 2,
        label: "Termos de Adesão ABR-UBA"
      },
      {
        step: 3,
        label: "Confirmação da Adesão"
      }
    ],
    accepted: undefined,
    detail: {
      algodoeira: {
        nome: undefined,
        razao_social: undefined,
        cpf_cnpj: undefined,
        municipio: undefined,
        codigo_gs1_digito_extensao: undefined,
        unidade_produtivas: [],
        categoria: undefined,
        safra_certificacao: undefined,
        proprietarios: []
      },
      status: {
        id: undefined,
        nome: undefined
      },
      convidados: []
    }
  }),

  async mounted() {
    const id = parseInt(this.$route.params.id, 10);
    await this.fetchInvite(id);
  },

  computed: {
    ...mapGetters({
      user: "inviteds/getUser"
    })
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("inviteUba", ["fetchInviteDetail", "sendNotionUBA"]),

    nextStep(step) {
      this.currentStep = step;
    },

    previousStep(step) {
      this.currentStep = step;
    },

    async fetchInvite(id) {
      await this.callFetchData(async () => {
        const data = await this.fetchInviteDetail(id);
        this.detail = this.formatData(data);
      });
    },

    async callFetchData(fn) {
      try {
        await fn();
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    formatData(detail) {
      const response = {
        ...detail,
        convidado: detail.convidados.find(
          convidado => convidado.id === this.user.convidado_id
        )
      };
      delete response.convidados;
      return response;
    },

    async sendNotion(accepted, callback) {
      await this.callFetchData(async () => {
        const id = parseInt(this.$route.params.id, 10);
        await this.sendNotionUBA({
          id,
          body: {
            aceitou: accepted
          }
        });
        this.accepted = accepted;
        callback();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-stepper__wrapper {
  width: 100% !important;
}

::v-deep .v-stepper__header {
  box-shadow: none;
  border-bottom: 1px solid #ccc;
}
</style>
