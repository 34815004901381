<template>
  <v-stepper-content :step="step" class="d-flex fill">
    <div class="mb-3">
      <p class="section-subtitle primary-text--text">
        Você foi convidado para ser Responsável ABR-UBA desta Unidade de
        Beneficiamento de Algodão:
      </p>

      <productive-unit-detail :productiveUnit="detail.algodoeira" />
    </div>

    <v-divider />

    <div class="my-3">
      <p class="section-subtitle primary-text--text">
        1- Confirme o aceite ao Regulamento do Programa ABR-UBA
      </p>

      <v-btn @click="handleDownloadRegulationAbrUba" color="primary"
        >Acessar o Regulamento do Programa ABR-UBA</v-btn
      >

      <div class="checkbox-regulation">
        <optional-tooltip
          :hide="alreadySawRegulation"
          text="É necessário acessar o Regulamento do Programa ABR-UBA (botão acima) para marcar que está de acordo"
        >
          <v-checkbox
            v-model="acceptedRegulation"
            :disabled="!alreadySawRegulation"
          >
            <template slot="label">
              <p class="ma-0 primary-text--text">
                Li e estou de acordo com o
                <strong>Regulamento do Programa ABR-UBA</strong>
              </p>
            </template>
          </v-checkbox>
        </optional-tooltip>
      </div>
    </div>

    <div class="d-flex fill justify-end">
      <v-btn text class="mr-2 px-8" @click="previusStep()" width="156">
        Cancelar
      </v-btn>
      <v-btn
        elevation="1"
        color="primary"
        size="large"
        @click="nextStep()"
        class="px-8"
        :disabled="nextBtnDisabled"
        :width="156"
      >
        Avançar
      </v-btn>
    </div>
  </v-stepper-content>
</template>

<script>
import ProductiveUnitDetail from "@/modules/invite-uba/views/components/manage-invite/ProductiveUnitDetail";
import OptionalTooltip from "@/modules/core/views/components/OptionalTooltip";

export default {
  name: "StepRegulation",

  props: {
    step: {
      type: Number,
      required: true
    },
    detail: {
      type: Object,
      required: true
    }
  },

  components: {
    ProductiveUnitDetail,
    OptionalTooltip
  },

  data: () => ({
    acceptedRegulation: false,
    alreadySawRegulation: false
  }),

  computed: {
    nextBtnDisabled() {
      return !this.acceptedRegulation;
    }
  },

  methods: {
    nextStep() {
      this.$emit("onNext", this.step + 1);
    },

    previusStep() {
      this.$emit("onPrevious", this.step - 1);
    },
    handleDownloadRegulationAbrUba() {
      this.alreadySawRegulation = true;
      window.open("/documentos/regulamento_abr_uba.pdf", "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-regulation {
  max-width: 500px;
}
</style>
