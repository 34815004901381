import { render, staticRenderFns } from "./StepRegulation.vue?vue&type=template&id=1d997ab2&scoped=true&"
import script from "./StepRegulation.vue?vue&type=script&lang=js&"
export * from "./StepRegulation.vue?vue&type=script&lang=js&"
import style0 from "./StepRegulation.vue?vue&type=style&index=0&id=1d997ab2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d997ab2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VCheckbox,VDivider,VStepperContent})
